:root {
  --burgerColor: #fff;
}
.burger-container {
  z-index: 9999;
  position: relative;
}
.burger-container input#burger-checkbox {
  cursor: pointer;
  position: absolute;
  width: 100%;
  width: 30px;
  height: 30px;
  z-index: 2;
  opacity: 0;
}

.burger {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 30px;
  height: 30px;
  max-width: 50px;
  max-height: 50px;

  transition: all 0.35s;
}

.burger div {
  border-radius: 10px;
  position: relative;
  background: var(--burgerColor);
  width: 100%;
  height: 4px;
  transition: all 0.35s;
}

.burger div::after {
  border-radius: 10px;
  position: absolute;
  top: -10px;
  background: var(--burgerColor);
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  transition: all 0.35s;
}
.burger div::before {
  border-radius: 10px;
  position: absolute;
  top: 10px;
  background: var(--burgerColor);
  content: '';
  display: block;
  height: 4px;
  width: 100%;
  transition: all 0.35s;
}

.burger-container input#burger-checkbox:checked ~ .burger div {
  transform: translateX(-10px);
  background-color: transparent;
}
.burger-container input#burger-checkbox:checked ~ .burger div:after {
  transform: translate(10px, 10px) rotate(45deg);
}
.burger-container input#burger-checkbox:checked ~ .burger div:before {
  transform: translate(10px, -10px) rotate(-45deg);
}
